import HttpFactory from '../factory'

class IntegrationsModule extends HttpFactory {

  RESOURCE = `integrations`

  async getProviders() {
    return await this.call(`get`, `${this.RESOURCE}/providers`)
  }

  async createProvider(data) {
    return await this.call(`post`, `${this.RESOURCE}/providers`, {
      name: data.name,
      api_key: data.api_key,
      base_uri: data.base_uri
    })
  }

  async updateProvider(data) {
    return await this.call(`put`, `${this.RESOURCE}/providers`, {
      name: data.name,
      api_key: data.api_key,
      base_uri: data.base_uri
    })
  }

  async askMoreDetailsAPI(data) {
    return await this.raw(`get`, `${this.RESOURCE}/ask_more_details`)
  }

}

export default IntegrationsModule
