import HttpFactory from '../factory'

class ScreeningsModule extends HttpFactory {
  RESOURCE = `/screenings`

  async me(id) {
    return await this.call(`get`, `${this.RESOURCE}/me/`)
  }

  async meLight() {
    return await this.call(`get`, `${this.RESOURCE}/me/light/`)
  }

  async allLight() {
    return await this.call(`get`, `${this.RESOURCE}/all/light`)
  }

  async getAll({
    url = `${this.RESOURCE}`,
    params = {
      // pageSize: 20,
      // pageNumber : 1,
    }
  } = {}) {
    const paramsString = this.paramsArray(params)
    const separator = url.includes(`?`) ? `&` : `?`
    const finalUrl = `${url}${separator}${paramsString}`
    return await this.call(`get`, finalUrl)
  }

  async get(id, lang = null) {
    const url = !!lang ? `${this.RESOURCE}/${id}/?lang=${lang}` : `${this.RESOURCE}/${id}/`
    return await this.call(`get`, url)
  }

  async send(screening) {
    return await this.call(`put`, `${this.RESOURCE}/${screening.id}/`, screening)
  }

  updateProfile(screeningId, profile) {
    return this.call(`put`, `${this.RESOURCE}/${screeningId}/profile/`, profile)
  }

  getDocuments(screeningId) {
    return this.raw(`get`, `${this.RESOURCE}/${screeningId}/documents/`)
  }

  sendDocuments(screeningId, documents) {
    return this.raw(`put`, `${this.RESOURCE}/${screeningId}/documents/`, documents)
  }

  transparency(screeningId, transparency) {
    return this.call(`put`, `${this.RESOURCE}/${screeningId}/transparency/`, transparency)
  }

  getScreeningStepsDocuments(screeningId, newDocumentsAnswer) {
    return this.call(`get`, `${this.RESOURCE}/${screeningId}/screening_steps/${newDocumentsAnswer}/documents/`)
  }

  saveScreeningStepsDocuments(screeningId, newDocumentsAnswer, params) {
    return this.raw(`post`, `${this.RESOURCE}/${screeningId}/screening_steps/${newDocumentsAnswer}/documents/`, params)
  }

  all_comments(screeningId) {
    return this.call(`get`, `${this.RESOURCE}/${screeningId}/all_comments/`)
  }

  review(screeningId) {
    return this.call(`get`, `${this.RESOURCE}/${screeningId}/review`)
  }

  submitReview(screeningId, payload) {
    return this.call(`post`, `${this.RESOURCE}/${screeningId}/review`, payload)
  }

  deleteReview(screeningId) {
    return this.call(`delete`, `${this.RESOURCE}/${screeningId}/review/delete`)
  }
}

export default ScreeningsModule
