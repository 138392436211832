const INDEX_NAME_ROUTE = `index`
const isIndexNameRoute = (name) => name === INDEX_NAME_ROUTE

const ROUTESWITHOUTMENU = [`logout`, `callback`, `mobile_signer`]

export default function ({ app, store, route }) {
  if (isIndexNameRoute(route?.name) && !app.$auth.loggedIn) {
    store.commit(`drawerMenu/closeDrawer`)
    store.commit(`drawerMenu/drawerDeactivated`)
  } else if (ROUTESWITHOUTMENU.includes(route?.name)) {
    store.commit(`drawerMenu/closeDrawer`)
    store.commit(`drawerMenu/drawerDeactivated`)
  } else {
    store.commit(`drawerMenu/openDrawer`)
    store.commit(`drawerMenu/drawerDisplayed`)
  }
}
