import HttpFactory from '../factory'

class DASignerModule extends HttpFactory {
  async getDeclarations(code, lang=undefined) {
    let url = `/declaration_of_authorisation/declarations/${code}`
    if (lang) {
      url += `?lang=${lang}`
    }
    return await this.call(`get`, url)
  }

  async generateDA(data, lang=undefined) {
    console.log(`lang`, lang)
    let url = `/declaration_of_authorisation/generate`
    if (lang) {
      url += `?lang=${lang}`
    }
    return await this.call(`post`, url, data);
  }

  async sendSignature(data) {
    return await this.call(`post`, `/declaration_of_authorisation/send_signature`, data)
  }

  async getSignature(data) {
    const token = data.token;
    return await this.call(`get`, `/declaration_of_authorisation/send_signature?token=${token}`)
  }

}

export default DASignerModule
